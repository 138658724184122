var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-input',{staticClass:"vb-input",attrs:{"aria-invalid":_vm.aria_invalid,"autocomplete":_vm.autocomplete,"autofocus":_vm.autofocus,"debounce":_vm.debounce,"disabled":_vm.disabled,"form":_vm.form,"formatter":_vm.formatter,"id":_vm.id,"lazy":_vm.lazy,"lazy-formatter":_vm.lazy_formatter,"list":_vm.list,"max":_vm.max,"min":_vm.min,"name":_vm.name,"no-wheel":_vm.no_wheel,"number":_vm.number,"placeholder":_vm.placeholder,"plaintext":_vm.plaintext,"readonly":_vm.readonly,"required":_vm.required,"size":_vm.size,"state":_vm.state,"step":_vm.step,"trim":_vm.trim,"type":_vm.type},on:{"blur":value => {
      if (_vm.vb_options.type == 'date') _vm.update_date_value()
      if (_vm.vb_options.price) {
        _vm.update_price_value()
        value = _vm.price_value
      }
      _vm.$emit('blur', value)
    },"change":function($event){return _vm.$emit('change', _vm.value)},"input":_vm.input_handler,"update":function($event){return _vm.$emit('update', _vm.value)},"focus":($event) => {
      $event.target.select()
    }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.vb_options.price)?_c('div',[_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.vb_options.rules,"name":_vm.vb_options.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, validate }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.price_value),expression:"price_value"}],ref:'price'+_vm._uid,attrs:{"type":"text","hidden":""},domProps:{"value":(_vm.price_value)},on:{"input":function($event){if($event.target.composing)return;_vm.price_value=$event.target.value}}}),_c('span',{staticClass:"vee--errors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,507663516)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }