<template>
  <div class="vb-card" :class="'vb-card--'+ variant">
    <b-row class="vb-card__wrapper">
      <b-col class="vb-card__element vb-card__element--image" v-if="card.image" cols="12" :lg="variant === 'horizontal' && '4'">
        <div class="vb-card__image" :style="'background-image:url('+ card.image +')'" @click.stop @click="$emit('click_image')">
          <span class="vb-card__image-corner"></span>
        </div>
      </b-col>
      <b-col class="vb-card__element vb-card__element--wrap" cols="12" :lg="(variant === 'horizontal' && card.image) && '8'">
        <div class="vb-card__wrap">
          <div class="vb-card__head" v-if="card.title || card.subtitle">
            <h4 class="vb-card__title" v-if="card.title" v-html="card.title" @click.stop @click="$emit('click_title')" />
            <p class="vb-card__subtitle" v-if="card.subtitle">
              <vb-icon
                v-if="card.subtitle_icon"
                class="vb-card__subtitle-icon"
                :name="card.subtitle_icon.name"
                :color="card.subtitle_icon.color"
                :size="card.subtitle_icon.size"
              />
              <span v-html="card.subtitle" />
            </p>
          </div>
          <p class="vb-card__text" v-if="card.text" v-html="card.text" />
          <slot name="default"></slot>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'vb-card',

  components: {
  },

  props: {
    variant: {
      type: String,
      default: 'vertical',
    },
    card: {
      type: Object,
      default: () => { return {} }
    }
  },

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  },

  methods: {
  }

}
</script>

<style lang="scss" scoped>

.vb-card {
  $this: &;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__wrapper {
    display: flex;
    flex: 1 1 auto;

    @at-root #{$this}--vertical & {
      flex-direction: column;
    }
  }

  &__element {

    &--wrap {
      display: flex;
      flex: 1 1 auto;
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    position: relative;
    z-index: 1;

    // standard e type vertical
    min-height: 300px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 102%, 85% 84%, 0 84%);

    @at-root #{$this}--horizontal & {
      @include media-breakpoint-up(lg) {
        min-height: 100%;
        clip-path: polygon(0 0, 101% 0, 91% 23%, 91% 100%, 0% 101%);
      }
    }

    &-corner {
      width: 30px;
      height: 30px;
      overflow: hidden;
      position: absolute;
      left: 0;
      bottom: 16%;
      z-index: 1;
      transform: scaleX(-1);

      @at-root #{$this}--horizontal & {
        @include media-breakpoint-up(lg) {
          left: auto;
          right: 9%;
          bottom: 0;
          transform: scaleX(1);
        }
      }

      &:before {
        content: "";
        width: 200%;
        height: 200%;
        position: absolute;
        border-radius: 5px;
        bottom: 0;
        right: 0;
        box-shadow: 50px 50px 0 0 $vb-body-bg;
      }
    }
  }

  &__wrap {
    background-color: $white;
    padding: $grid-gutter-width/2;
    border-radius: 0.313rem;
    min-height: 100%;
    flex: 1 1 auto;

    @at-root #{$this}__element--image + #{$this}__element--wrap & {
      margin-top: -2.375rem;
    }

    @at-root #{$this}--horizontal #{$this}__element--image + #{$this}__element--wrap & {
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: -2.5rem;
      }
    }
  }

  &__head {
    & + * {
      margin-top: $grid-gutter-width/6;
      padding-top: $grid-gutter-width/6;
      border-top: 1px solid $gray-200;
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: $font-size-lg;
    color: $dark;
    font-weight: $vb-font-weight-semibold;
  }

  &__subtitle {
    margin-bottom: 0;
    color: $gray-500;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 5px;
    }
  }

  &__text {
    margin-bottom: 0;
  }
}

</style>