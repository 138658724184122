import air_arrival from './air-arrival.js'
import air_start from './air-start.js'
import airplane from './airplane.js'
import alert from './alert.js'
import apple from './apple.js'
import arrow_l from './arrow-l.js'
import arrow_r from './arrow-r.js'
import baggage from './baggage.js'
import bed from './bed.js'
import bus from './bus.js'
import calendar_edit from './calendar-edit.js'
import calendar from './calendar.js'
import call_center from './call-center.js'
import camera from './camera.js'
import cart from './cart.js'
import chat from './chat.js'
import check from './check.js'
import clock from './clock.js'
import cutlery from './cutlery.js'
import doublebed from './double-bed.js'
import drink from './drink.js'
import email from './email.js'
import engine from './engine.js'
import euro from './euro.js'
import eye_close from './eye-close.js'
import eye_open from './eye-open.js'
import faq from './faq.js'
import filter from './filter.js'
import hands from './hands.js'
import happy_pin from './happy-pin.js'
import heart from './heart.js'
import hotel from './hotel.js'
import info from './info.js'
import lamp from './lamp.js'
import lock from './lock.js'
import logout from './logout.js'
import menu from './menu.js'
import moon from './moon.js'
import occupants from './occupants.js'
import phone from './phone.js'
import pin from './pin.js'
import plus from './plus.js'
import plus_circle from './plus-circle.js'
import plus_v2 from './plus-v2.js'
import quote from './quote.js'
import round_trip from './round-trip.js'
import shield from './shield.js'
import star from './star.js'
import tags from './tags.js'
import technology from './technology.js'
import ticket from './ticket.js'
import umbrella from './umbrella.js'
import user from './user.js'
import wallet from './wallet.js'
import zoom from './zoom.js'
import parking from './parking.js'
import pets from './pets.js'
import accessibility from './accessibility.js'
import business_center from './business-center.js'
import children_services from './children-services.js'
import pool from './pool.js'
import restaurant from './restaurant.js'
import spa from './spa.js'
import like from './like.js'
import prohibition from './prohibition.js'
import cot from './cot.js'
import shower from './shower.js'
import room_size from './room_size.js'
import door from './door.js'
import breakfast from './breakfast.js'
import dotted from './dotted.js'
import flag from './flag.js'
import minus_circle from './minus-circle.js'
import sun from './sun.js'
import beach from './beach.js'
import camping from './camping.js'
import lock_v2 from './lock-v2.js'
import check_circle from './check-circle.js'
import exclamation from './exclamation.js'
import gallery from './gallery.js'
import angle_left from './angle-left.js'
import angle_right from './angle-right.js'
import print from './print.js'
import remove from './remove.js'
import charging from './charging.js'
import leaf from './leaf.js'
import angle_small_up from './angle-small-up.js'
import angle_small_right from './angle-small-right.js'
import angle_small_down from './angle-small-down.js'
import angle_small_left from './angle-small-left.js'
import hamburger from './hamburger.js'
import store_android from './store-android.js'
import store_apple from './store-apple.js'
import whatsapp from './whatsapp.js'
import armchair from './armchair.js'
import login from './login.js'

export {
  baggage,
  air_arrival,
  air_start,
  airplane,
  alert,
  apple,
  arrow_l,
  arrow_r,
  bed,
  bus,
  calendar_edit,
  calendar,
  call_center,
  camera,
  cart,
  chat,
  check,
  clock,
  cutlery,
  doublebed,
  drink,
  email,
  engine,
  euro,
  exclamation,
  eye_close,
  eye_open,
  faq,
  filter,
  hands,
  happy_pin,
  heart,
  hotel,
  info,
  lamp,
  lock,
  logout,
  menu,
  moon,
  occupants,
  phone,
  pin,
  plus,
  plus_circle,
  plus_v2,
  quote,
  round_trip,
  shield,
  star,
  tags,
  technology,
  ticket,
  umbrella,
  user,
  wallet,
  zoom,
  parking,
  pets,
  accessibility,
  business_center,
  children_services,
  pool,
  restaurant,
  spa,
  like,
  prohibition,
  cot,
  shower,
  room_size,
  door,
  breakfast,
  dotted,
  flag,
  minus_circle,
  sun,
  beach,
  camping,
  lock_v2,
  check_circle,
  gallery,
  angle_left,
  angle_right,
  print,
  remove,
  charging,
  leaf,
  angle_small_up,
  angle_small_right,
  angle_small_down,
  angle_small_left,
  hamburger,
  store_android,
  store_apple,
  whatsapp,
  armchair,
  login,
}