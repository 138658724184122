<template>
  <div>
    <b-form-input
      v-model="value"
      class="vb-input"
      :aria-invalid="aria_invalid"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :debounce="debounce"
      :disabled="disabled"
      :form="form"
      :formatter="formatter"
      :id="id"
      :lazy="lazy"
      :lazy-formatter="lazy_formatter"
      :list="list"
      :max="max"
      :min="min"
      :name="name"
      :no-wheel="no_wheel"
      :number="number"
      :placeholder="placeholder"
      :plaintext="plaintext"
      :readonly="readonly"
      :required="required"
      :size="size"
      :state="state"
      :step="step"
      :trim="trim"
      :type="type"
      @blur="value => {
        if (vb_options.type == 'date') update_date_value()
        if (vb_options.price) {
          update_price_value()
          value = price_value
        }
        $emit('blur', value)
      }"
      @change="$emit('change', value)"
      @input="input_handler"
      @update="$emit('update', value)"
      @focus="($event) => {
        $event.target.select()
      }"
    />
      <div v-if="vb_options.price">
        <validation-provider mode="eager"
          :rules="vb_options.rules"
          :name="vb_options.name"
          v-slot="{ errors, validate }">
            <input type="text" hidden v-model="price_value" :ref="'price'+_uid"/>
            <span class="vee--errors">{{ errors[0] }}</span>
        </validation-provider>
      </div>
  </div>

</template>

<script>
import { number } from 'mathjs'
import moment from 'moment'

export default {
  name: 'vb-input',

  components: {},

  props: {
    vb_options: {
      type: Object,
      default: () => { }
    }
  },

  data() {
    return {
      // bootstrap-vue options => https://bootstrap-vue.org/docs/components/form-input#form-input
      aria_invalid: this.vb_options.aria_invalid !== undefined ? this.vb_options.aria_invalid : false,                        //Sets the 'aria-invalid' attribute with the specified value
      autocomplete: this.vb_options.autocomplete !== undefined ? this.vb_options.autocomplete : '',                           //Sets the 'autocomplete' attribute value on the form control
      autofocus: this.vb_options.autofocus !== undefined ? this.vb_options.autofocus : false,                                 //When set to `true`, attempts to auto-focus the control when it is mounted, or re-activated when in a keep-alive. Does not set the `autofocus` attribute on the control button this.vb_options.value !== undefined ? this.vb_options.value : false                         //When set, renders the checkbox with the appearance of a button
      debounce: this.vb_options.debounce !== undefined ? this.vb_options.debounce : 0,                                        //When set to a number of milliseconds greater than zero, will debounce the user input. Has no effect if prop 'lazy' is set
      //disabled: this.vb_options.disabled !== undefined ? this.vb_options.disabled : false,                                  //When set to `true`, disables the component's functionality and places it in a disabled state
      form: this.vb_options.form !== undefined ? this.vb_options.form : '',                                                   //ID of the form that the form control belongs to. Sets the `form` attribute on the control
      formatter: this.vb_options.formatter !== undefined ? this.vb_options.formatter : null,                                  //Reference to a function for formatting the input
      id: this.vb_options.id !== undefined ? this.vb_options.id : '',                                                         //Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
      lazy: this.vb_options.lazy !== undefined ? this.vb_options.lazy : false,                                                //When set, updates the v-model on 'change'/'blur' events instead of 'input'. Emulates the Vue '.lazy' v-model modifier
      lazy_formatter: this.vb_options.lazy_formatter !== undefined ? this.vb_options.lazy_formatter : false,                  //When set, the input is formatted on blur instead of each keystroke (if there is a formatter specified)
      list: this.vb_options.list !== undefined ? this.vb_options.list : '',                                                   //The ID of the associated datalist element or component
      max: this.vb_options.max !== undefined ? this.vb_options.max : '',                                                      //Value to set in the 'max' attribute on the input. Used by number-like inputs
      min: this.vb_options.min !== undefined ? this.vb_options.min : '',                                                      //Value to set in the 'min' attribute on the input. Used by number-like inputs
      name: this.vb_options.name !== undefined ? this.vb_options.name : '',                                                   //Sets the value of the `name` attribute on the form control
      no_wheel: this.vb_options.no_wheel !== undefined ? this.vb_options.no_wheel : false,                                    //For number-like inputs, disables the mouse wheel from incrementing or decrementing the value
      number: this.vb_options.number !== undefined ? this.vb_options.number : false,                                          //When set attempts to convert the input value to a native number. Emulates the Vue '.number' v-model modifier
      placeholder: this.vb_options.placeholder !== undefined ? this.vb_options.placeholder : '',                              //Sets the `placeholder` attribute value on the form control     
      plaintext: this.vb_options.plaintext !== undefined ? this.vb_options.plaintext : false,                                 //Set the form control as readonly and renders the control to look like plain text (no borders)
      readonly: this.vb_options.readonly !== undefined ? this.vb_options.readonly : false,                                    //Sets the `readonly` attribute on the form control
      required: this.vb_options.required !== undefined ? this.vb_options.required : false,                                    //Adds the `required` attribute to the form control
      size: this.vb_options.size !== undefined ? this.vb_options.size : '',                                                   //Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
      state: this.vb_options.state !== undefined ? this.vb_options.state : null,                                              //Controls the validation state appearance of the component. `true` for valid, `false` for invalid, or `null` for no validation state
      step: this.vb_options.step !== undefined ? this.vb_options.step : '',                                                   //Value to set in the 'step' attribute on the input. Used by number-like inputs
      trim: this.vb_options.trim !== undefined ? this.vb_options.trim : false,                                                //When set, trims any leading and trailing white space from the input value. Emulates the Vue '.trim' v-model modifier
      type: this.vb_options.type !== undefined ? this.vb_options.type : 'text',                                               //The type of input to render. See the docs for supported types
      value: this.vb_options.value !== undefined ? this.vb_options.value : '',                                                //The current value of the input. Result will always be a string, except when the `number` prop is used

      // custom data
      price_value: this.value,
    }
  },

  created() {
  },

  computed: {
    disabled() {                                                                                                              //When set to `true`, disables the component's functionality and places it in a disabled state
      return this.vb_options.disabled !== undefined ? this.vb_options.disabled : false
    },
  },

  mounted() {
    // set value to parent's v-model (if v-model is set)
    if (this.$options._parentVnode.data.model) this.value = this.$options._parentVnode.data.model.value
    // set default date if provided as "value"
    if (this.vb_options.type == 'date') {
      if (this.vb_options.value !== undefined) this.input_handler(this.vb_options.value)
    }
  },

  methods: {
    input_handler(value) {
      if (this.vb_options.type == 'date') {
        let date = moment(value)
        if (!date._isValid) {
          if (value !== '') this.$emit('input', null)
          return
        }
        if (date.year().toString().length < 4) return
        let checked_date
        // check upper bound
        checked_date = moment.min(date, moment(this.max))
        // check lower bound
        checked_date = moment.max(checked_date, moment(this.min))
        value = checked_date.format('YYYY-MM-DD')
      }
      if (this.vb_options.price) {
        this.update_price_value()
        value = this.price_value
      }
      this.$nextTick(() => {
        this.value = value
        this.$emit('input', this.value)
      })
    },
    update_date_value() {
      let date = moment(this.value)
      if (date.year().toString().length < 4) {
        date.year(moment(this.min).year())
      }
      this.input_handler(date.format('YYYY-MM-DD'))
    },
    update_price_value() {
      this.price_value = this.value.replaceAll(',', '.')
      this.price_value = this.price_value.replace(/[^\d.]/g, '')
      this.price_value = number(this.price_value)
      var evt = document.createEvent("HTMLEvents")
      evt.initEvent("change", false, true)
      this.$refs['price'+this._uid].dispatchEvent(evt)
    }, 
  }
}
</script>

<style lang="scss">
  .vb-input {
    transition: $transition-base;

    &::placeholder {
      font-size: $font-size-sm;
    }

    &:focus, &.focus {
      border-color: $input-focus-border-color;
      box-shadow: inset .15rem .15rem .15rem rgba($info, .1), 0 .3rem .75rem rgba($info, .15) !important;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
</style>